<template>
  <div>
    <div
      class="card"
      style="background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 10px;
              overflow-y:auto;"
    >
      <div
        class="pl-3 pr-3 pt-2"
      >
        <div class="">
          <span class="saj-title">
            {{ $t("Search Filter") }}
          </span>
        </div>
        <div class="row align-items-end mb-1">
          <!-- if admin -->
          <!-- <template v-if="roles.selectedRole === 1"> -->
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Subsidiary") }}:</label>
            <!-- <b-form-select
              v-model="selectedSub"
              :options="subList"
              :value="subList"
              size="sm"
              class="saj-text"
              :disabled="roles.selectedRole === 6"
              @input="getDepartment(selectedSub)"
            /> -->
            <v-select
              v-model="selectedSub"
              class="saj-text select-size-sm"
              style="background: white"
              :placeholder="$t('Choose Subsidiary')"
              :options="subList"
              :reduce="sub => sub.value"
              label="text"
              :disabled="roles.selectedRole === 6"
              @input="getDepartment(selectedSub), selectedSub === null ? selectedDepartment = null : ''"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 pt-1"
          >
            <label class="saj-text">{{ $t("Department") }}:</label>
            <!-- <b-form-select
              v-model="selectedDepartment"
              :options="departmentList"
              :value="departmentList"
              size="sm"
              class="saj-text"
              :disabled="selectedSub === null"
            /> -->
            <v-select
              v-model="selectedDepartment"
              class="saj-text select-size-sm"
              style="background: white"
              :placeholder="$t('Choose Department')"
              :options="departmentList"
              :reduce="dept => dept.value"
              label="text"
              :disabled="isSub"
            />
          </b-col>
          <!-- </template> -->
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Employee Number") }}:</label>
            <!-- <b-form-select
              v-model="selectedEmployeeID"
              :options="empIDList"
              :value="empIDList"
              size="sm"
              class="saj-text"
            /> -->
            <b-form-input
              v-model="selectedEmployeeID"
              :placeholder="$t('Search Employee Number')"
              size="sm"
              class="saj-text"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mr-1 pt-1"
          >
            <label class="saj-text">{{ $t("Transfer Type") }}:</label>
            <b-form-select
              id="icons-search"
              v-model="transferType"
              :options="transferList"
              size="sm"
              class="saj-text"
            />
          </b-col>
          <div class="d-flex">
            <b-col>
              <b-button
                md="4"
                variant="primary"
                class="d-flex justify-content-center"
                style=""
                @click="show=true, roles.selectedRole === 1 ? getListSuperHR() : getListHR()"
              >

                <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
              </b-button>
            </b-col>
            <b-col>
              <b-button
                md="4"
                variant="primary"
                class="saj-title d-flex justify-content-center"
                block
                style="
          "
                @click="clearButton()"
              >
                <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
              </b-button>
            </b-col>
          </div>
        <!-- <div class="p-1 bg-success" style="width: 500px; overflow: auto;">
          {{ finalData }}
        </div> -->

        </div>
        <div
          class=""
        >
          <b-overlay
            :show="show"
            rounded="sm"
          >
            <template #overlay>
              <div class="d-flex flex-column align-items-center justify-content-center">
                <b-spinner
                  variant="primary"
                  label="Spinning"
                />
                <p>{{ $t('Fetching Data') }}... </p>
              </div>
            </template>
            <div class="row pl-1 pr-1">
              <b-table
                class="styleTable mt-1"
                style=""
                :items="companyList"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                label-size="lg"
                bordered
                show-empty
                responsive
              >
                <template #head()="data">
                  <span
                    class="saj-text d-flex justify-content-center"
                  >{{ $t(data.label) }}</span>
                </template>
                <template #cell(index)="data">
                  <div
                    class="saj-text d-flex justify-content-center"
                  >
                    {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage-1) *10) }}
                  </div>
                </template>
                <template #empty="">
                  <h4 style="text-align: center; font-style: italic">
                    {{ $t('There are no records to show') }}
                  </h4>
                </template>
                <template #cell(employee_name)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.employee_name }}
                  </div>
                </template>
                <template #cell(previous_subsidiary_name)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.previous_subsidiary_name }}
                  </div>
                </template>
                <template #cell(prev_department_name)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.prev_department_name }}
                  </div>
                </template>
                <template #cell(prev_position)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.prev_position }}
                  </div>
                </template>
                <template #cell(subsidiary_name)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.subsidiary_name }}
                  </div>
                </template>
                <template #cell(type)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ $t(data.item.type) }}
                  </div>
                </template>
                <template #cell(action)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    <!-- {{ data.item.transfer_id }} -->
                    <div>

                      <feather-icon
                        v-b-modal.transfer-details
                        icon="EyeIcon"
                        color="purple"
                        class="hoverIcon"
                        size="25"
                        @click="transferID = data.item.transfer_id"
                      />
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <b-row v-if="companyList.length > 0">
              <b-col>
                <span
                  class="saj-text"
                  align="start"
                >
                  {{ $t("Showing") }} {{ rows > perPage*currentPage ? perPage*currentPage : rows }} {{ $t("from") }} {{ rows }} {{ $t("entries") }}
                  <!-- {{ $t("Showing") }} 1 {{ $t("to") }} 2 {{ $t("of") }} 3 {{ $t("entries") }} -->
                </span>
              </b-col>
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  pills
                  aria-controls="employee-table"
                  class="saj-text mb-1 justify-content-end"
                />
              </b-col>
            </b-row>
          </b-overlay>

          <div
            v-if="companyList.length > 0"
            class=" d-flex justify-content-center"
          >
            <b-button
              variant="primary"
              class="ml-1 saj-button mb-1"
              :style="roles.isHOD || roles.isHR || roles.isAdmin ? `` : `display: none;`"
              :disabled="exporting"
              @click="exportHistory()"
            >
              <div
                v-if="exporting"
                class="d-flex"
              >
                <b-spinner
                  small
                  type="grow"
                  class="mr-1"
                />
                <span
                  class="saj-button d-md-none d-lg-block"
                > {{ $t("Exporting") }} </span>
              </div>
              <div v-else>
                <span
                  class="saj-button d-md-none d-lg-block"
                > {{ $t("Export History") }} </span>
              </div>

            </b-button>
          </div>

          <b-modal
            id="transfer-details"
            :hide-header="true"
            :hide-footer="true"
            :centered="true"
            size="lg"
          >
            <!-- $bvModal.hide(nama modal id) untuk tutup modal-->
            <emp-details
              :transfer="transferID"
              @close="$bvModal.hide('transfer-details')"
              @transfer-details="$bvModal.hide('transfer-details')"
            />
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  // BFormRadio,
  // BModal,
  BFormInput,
  BFormSelect,
  BRow,
  BTable,
  BCol,
  BButton,
  BOverlay,
  // BButtonGroup,
  BPagination,
  BSpinner,

} from "bootstrap-vue"
import SAJToast from '@/component/saj-toastification.vue'
import empDetails from '@/component/transfer-promotion/transfer&promotion-details.vue'
import vSelect from 'vue-select'
import FileSaver from 'file-saver'

import { mapGetters } from 'vuex'

export default {
  components: {
    empDetails,
    // BModal,
    // BFormRadio,
    BFormInput,
    BFormSelect,
    BRow,
    BTable,
    BCol,
    BButton,
    BOverlay,
    // BButtonGroup,
    BPagination,
    BSpinner,
    vSelect,
  },

  data(){
    return {
      isSub: true,
      subList: [],
      selectedSub: null,
      departmentList: [],
      selectedDepartment: null,
      empIDList: [{ value: null, text: `${this.$t('Choose Employee Id')}`, disabled: true }],
      selectedEmployeeID: null,
      transferType: null,
      // filter end
      transferID: null,
      companyList: [],
      subsidiaryList: [],
      subsidiary_id: null,
      tabIndex: 1,
      show: true,
      currentPage: 1,
      perPage: 10,
      rows: null,
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'employee_name', label: 'Name', thClass: 'text-center',
        },
        {
          key: 'previous_subsidiary_name', label: 'Previous Company', thClass: 'text-center',
        },
        {
          key: 'prev_department_name', label: 'Previous Department', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'prev_position', label: 'Previous Position', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'subsidiary_name', label: 'Promoted/Transferred Company', thClass: 'text-center',
        },
        {
          key: 'type', label: 'Type', thClass: 'text-center', dClass: 'text-center',
        },
        {
          key: 'action', label: 'Action', tdClass: 'text-center',
        },
      ],
      allbusinessUnit: null,
      alldepartment: null,
      transferList: [
        {
          value: null,
          text: `${this.$t("Choose Transfer Type")}`,
          disabled: true,
        },
        {
          value: 'Transfer',
          text: `${this.$t('Transfer')}`,
        },
        {
          value: 'Promotion',
          text: `${this.$t('Promotion')}`,
        },
        {
          value: 'Promotion & Transfer',
          text: `${this.$t('Promotion & Transfer')}`,
        },
      ],
      exporting: false,

    }
  },

  computed: {
    ...mapGetters(['roles']),
  },
  watch: {
    $route: {
      handler(){
        this.getSub_Id()
      },

    },
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.transferList = [
        {
          value: null,
          text: `${this.$t("Choose Transfer Type")}`,
          disabled: true,
        },
        {
          value: 'Transfer',
          text: `${this.$t('Transfer')}`,
        },
        {
          value: 'Promotion',
          text: `${this.$t('Promotion')}`,
        },
        {
          value: 'Transfer & Promotion',
          text: `${this.$t('Transfer & Promotion')}`,
        },
      ]
    },
  },

  mounted(){
    this.getSub_Id()
    this.getSubsidiary()
    if (this.roles.selectedRole === 6){
      this.isSub = false
    }
  },

  methods: {
    clearButton(){
      this.selectedDepartment = null
      this.selectedEmployeeID = null
      if (this.roles.selectedRole === 1){
        this.selectedSub = null
      }
      // console.log('role', this.roles.selectedRole)
      this.transferType = null
      this.show = true
      this.currentPage = 1
      setTimeout(() => {
        if (this.roles.selectedRole === 1){
          this.getListSuperHR()
        } else {
          this.getListHR()
        }
      }, 500)
    },
    getSub_Id() {
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
        this.subsidiary_id = response.data.data.employee.business_unit
        this.checkUser()
        if (this.roles.selectedRole === 6){
          this.getDepartment(this.subsidiary_id)
        }

        // console.log("siniii sub id user", this.subsidiary_id)
      })
    },
    checkUser() {
      const currentRole = this.roles.selectedRole
      if (currentRole === 6){
        this.selectedSub = this.subsidiary_id
        this.getListHR()
      } else if (currentRole === 1){
        this.getListSuperHR()
      }
      // console.log("sini current role", currentRole)
    },
    getSubsidiary(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        this.allbusinessUnit = response.data.data.subsidiaries
        // this.allbusinessUnit.forEach(b => {
        //   this.businessUnitList.push({
        //     text: b.subsidiary_name,
        //     value: b.id,

        //   })
        // })

        this.subList = this.allbusinessUnit.map(b => ({
          text: b.subsidiary_name,
          value: b.id,
        }))
      })
    },
    getDepartment(dept){
      this.isSub = true
      if (dept !== null){
        this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${dept}`).then(response => {
          const result = response.data
          // console.log('res', result)
          if (result.success){
            this.alldepartment = response.data.data

            this.departmentList = this.alldepartment.map(d => ({
              text: d.name,
              value: d.id,
            }))

            // if (this.selectedDepartment !== null){
            this.isSub = false
            this.selectedDepartment = null
            // }
          } else {
            this.departmentList = []
            this.selectedDepartment = null
          }
        })
      }
    },
    getListSuperHR() {
      const data = new FormData()

      if (this.selectedSub !== null){
        data.append('subsidiary_id', this.selectedSub)
      }

      if (this.selectedDepartment !== null){
        data.append('department_id', this.selectedDepartment)
      }

      if (this.selectedEmployeeID !== null){
        data.append('employee_number', this.selectedEmployeeID)
      }

      if (this.transferType !== null){
        data.append('type', this.transferType)
      }

      this.$axios.post(`${this.$baseUrl}/transfer_promotion/get_history`, data).then(response => {
        if (response.data.data.length !== 0){
          this.companyList = response.data.data
          // console.log(this.companyList)
          // console.log("heree", response.data.data.subsidiaries)
          // this.companyList.forEach(list => {
          //   this.subsidiaryList.push(list.id)
          // })

          this.subsidiaryList = this.companyList.map(list => (
            list.id
          ))
          // console.log("subsidiary id list", this.subsidiaryList)
        } else {
          this.companyList = []
        }
        this.show = false

        this.rows = this.companyList.length
      }).catch(() => {
        this.show = false
        this.companyList = []
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('No record retrieved')}.`,
              icon: "InfoIcon",
              variant: "success",
              titleColor: "#000",
              iconBg: '#53b7db',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },
    getListHR() {
      // console.log()
      const data = new FormData()

      if (this.selectedDepartment !== null){
        data.append('department_id', this.selectedDepartment)
      }

      if (this.selectedEmployeeID !== null){
        data.append('employee_number', this.selectedEmployeeID)
      }

      if (this.transferType !== null){
        data.append('type', this.transferType)
      }

      data.append("subsidiary_id", this.subsidiary_id)
      this.$axios.post(`${this.$baseUrl}/transfer_promotion/get_history`, data).then(response => {
        if (response.data.data.length !== 0){
          this.companyList = response.data.data
          // this.companyList.forEach(list => {
          //   this.subsidiaryList.push(list.id)
          //   // console.log("subsidiary id ", list.id)
          // })
          // console.log(response)
          // console.log("subsidiary id list", this.subsidiaryList)
          this.subsidiaryList = this.companyList.map(list => (
            list.id
          ))
        } else {
          this.companyList = []
        }
        this.show = false
        this.rows = this.companyList.length
      }).catch(() => {
        this.show = false
        this.companyList = []
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('No record retrieved')}.`,
              icon: "InfoIcon",
              variant: "success",
              titleColor: "#000",
              iconBg: '#53b7db',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },
    exportHistory(){
      this.exporting = true
      // const params = new URLSearchParams()
      // // params.append(`subsidiary_id`, this.userDetails.business_unit)
      // // params.append(`department_id`, this.userDetails.department_id)
      let param = null

      // console.log("sini role", this.roles.selectedRole)

      if (this.selectedSub !== null){
        param = `subsidiary_id=${this.selectedSub}`
      }

      if (this.selectedDepartment !== null){
        param += `&department_id=${this.selectedDepartment}`
      }

      if (this.selectedEmployeeID !== null){
        param += `&employee_number=${this.selectedEmployeeID}`
      }

      if (this.transferType !== null){
        if (this.transferType === 'Promotion & Transfer'){
          param += `&type=Promotion %26 Transfer`
        } else {
          param += `&type=${this.transferType}`
        }
      }

      // console.log('parameter', param)
      this.exporting = false

      // const cutURL = url.split('.com:8080/')
      // console.log('url', cutURL[0].concat(`/${cutURL[1]}`))
      // FileSaver.saveAs(`${[url]}`, "Performance_Management_Summary.xlsx")

      let x

      if (param === null){
        x = `https://api.ranhill-pms.com/api/transfer_promotion/export_history`
      } else {
        x = `https://api.ranhill-pms.com/api/transfer_promotion/export_history?${param}`
      }

      // console.log('x', x)
      // const url = x

      // console.log('url', url)

      this.$axios.get(`${x}`, {
        responseType: 'blob',
      }).then(response => {
        // console.log('file dw', response)
        if (response.status === 200){
          this.exporting = false
          FileSaver.saveAs(response.data, "Transfer_Promotion_History.xlsx")
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Your Excel file will be downloaded soon')}!`,
                icon: "CheckCircleIcon",
                variant: "info",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
      })
        .catch(() => {
          // console.log(error)
        })
    },
  },
}
</script>
<style scoped>
.Margin {
  margin : 0.5rem;
}
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
}

:hover::-webkit-scrollbar-thumb {

    /* background-color: #a0a0a5 !important; */
    background-color: rgb(255, 0, 0)
}

::-webkit-scrollbar-thumb:hover {
    border: 2px solid #bbb9b9 !important;
}
</style>
