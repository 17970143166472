<template>

  <div
    class="p-1"
    style="width: auto; height: 60vh; overflow-y: scroll;"
  >
    <div
      class="mb-3 d-flex justify-content-center"
      style="font-size: 30px;
      font-weight: 600;"
    >
      <!-- section 1 -->
      {{ $t ("Transfer/Promotion Details") }}
    </div>

    <div class="mb-2 saj-title">
      <div>
        {{ $t ("Employee Number") }}:
        {{ employee_number }}
      </div>
      <div>
        {{ $t ("Employee Name") }}:
        {{ name }}
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="saj-title mb-2 ">
          {{ $t ("Previous Work Information") }}
        </div>
        <div class="saj-title">
          {{ $t ("Subsidiary") }}
        </div>
        <div class="saj-text mb-1">
          {{ prevsubsidiary }}
        </div>
        <div class="saj-title">
          {{ $t ("Department") }}
        </div>
        <div class="saj-text mb-1">
          {{ prevdepartment }}
        </div>
        <div class="saj-title">
          {{ $t ("Branch") }}
        </div>
        <div class="saj-text mb-1">
          {{ prevbranch }}
        </div>
        <div class="saj-title">
          {{ $t ("Grade") }}
        </div>
        <div class="saj-text mb-1">
          {{ prevgrade }}
        </div>
        <div class="saj-title">
          {{ $t ("Grade Set") }}
        </div>
        <div class="saj-text mb-1">
          {{ prevgradeset }}
        </div>
        <div class="saj-title">
          {{ $t ("Position") }}
        </div>
        <div class="saj-text mb-1">
          {{ prevposition }}
        </div>

      </div>
      <div class="col-lg-6">
        <div class="saj-title mb-2">
          {{ $t ("Current Work Information") }}
        </div>
        <div class="saj-title">
          {{ $t ("Subsidiary") }}
        </div>
        <div class="saj-text mb-1">
          {{ subsidiary }}
        </div>
        <div class="saj-title">
          {{ $t ("Department") }}
        </div>
        <div class="saj-text mb-1">
          {{ department }}
        </div>
        <div class="saj-title">
          {{ $t ("Branch") }}
        </div>
        <div class="saj-text mb-1">
          {{ branch }}
        </div>
        <div class="saj-title">
          {{ $t ("Grade") }}
        </div>
        <div class="saj-text mb-1">
          {{ grade }}
        </div>
        <div class="saj-title">
          {{ $t ("Grade Set") }}
        </div>
        <div class="saj-text mb-1">
          {{ gradeset }}
        </div>
        <div class="saj-title">
          {{ $t ("Position") }}
        </div>
        <div class="saj-text mb-1">
          {{ position }}
        </div>

      </div>
    </div>
  </div>

</template>
<script>
import {
//   BForm,
} from "bootstrap-vue"
// import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
  },
  props: {
    transfer: {
      type: Number,
      default: null,
    },

  },
  data() {
    return {
      employee_number: null,
      name: null,
      subsidiary: null,
      department: null,
      branch: null,
      grade: null,
      gradeset: null,
      position: null,
      prevsubsidiary: null,
      prevdepartment: null,
      prevbranch: null,
      prevgrade: null,
      prevgradeset: null,
      prevposition: null,

    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getDetails() {
      const data = new FormData()
      data.append('transfer_id', this.transfer)
      this.$axios.post(`${this.$baseUrl}/transfer_promotion/get_history`, data).then(response => {
        if (response.data.data.length !== 0){
          this.show = false
          const Data = response.data.data[0]
          this.employee_number = Data.employee_number
          this.name = Data.employee_name

          this.prevsubsidiary = Data.previous_subsidiary_name
          this.prevdepartment = Data.prev_department_name
          this.prevbranch = Data.prev_branch_name
          this.prevgrade = Data.prev_grade
          this.prevgradeset = Data.prev_gradeset
          this.prevposition = Data.prev_position

          this.subsidiary = Data.subsidiary_name
          this.department = Data.department_name
          this.branch = Data.branch_name
          this.grade = Data.grade
          this.gradeset = Data.gradeset
          this.position = Data.position

          // console.log("detailssss", this.name)
        }
      }).catch(() => {
        this.show = false
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
  .dateJoined .flatpickr-input, .flatpickr-input ~ .form-control, .flatpickr-human-friendly {
  height: 53px !important
}
</style>

<!-- .flatpickr-input, .flatpickr-input ~ .form-control, .flatpickr-human-friendly -->
