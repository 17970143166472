<template>
  <div>
    <div
      class="card"
      style="background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 10px;
              overflow-y:auto;"
    >
      <div
        class="pl-3 pr-3 pt-2"
      >
        <div class="saj-title d-flex justify-content-end">
          <b-form-input
            v-model="searchCompany"
            style="width: 250px;"
            :placeholder="$t('Search Company')"
            :style="roles.isHR ? `display: none;` : ``"
            size="md"
            class="saj-text"
          />
        </div>
        <div
          class=""
        >
          <b-overlay
            :show="show"
            rounded="sm"
          >
            <template #overlay>
              <p>{{ $t('Fetching Data') }}... </p>
            </template>
            <div class="row pl-1 pr-1">
              <b-table
                class="styleTable mt-1"
                style=""
                :items="companyList"
                :fields="fields"
                :filter="searchCompany"
                label-size="lg"
                bordered
                show-empty
                responsive
              >
                <template #head()="data">
                  <span
                    class="saj-text d-flex justify-content-center"
                  >{{ $t(data.label) }}</span>
                </template>
                <template #cell(index)="data">
                  <div
                    class="saj-text d-flex justify-content-center"
                  >
                    {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage) *10) }}
                  </div>
                </template>
                <template #cell(subsidiary_name)="data">
                  <div
                    class="saj-text d-flex justify-content-start"
                  >
                    {{ data.item.subsidiary_name }}
                  </div>
                </template>
                <template #cell(action)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    <!-- {{ data.item.id }} -->
                    <b-button
                      v-b-modal.transfer-info
                      class="mr-1 Margin"
                      style=""
                      variant="primary"
                      @click="tempSubID = data.item.id, type = 'Transfer'"
                    >
                      {{ $t("Transfer") }}
                    </b-button>
                    <b-button
                      v-b-modal.transfer-info
                      class="mr-1 Margin"
                      variant="primary"
                      @click="tempSubID = data.item.id, type = 'Promotion'"
                    >
                      {{ $t("Promotion") }}
                    </b-button>
                    <b-button
                      v-b-modal.transfer-info
                      class="Margin"
                      variant="primary"
                      @click="tempSubID = data.item.id, type = 'Promotion & Transfer'"
                    >
                      {{ $t("Promotion & Transfer") }}
                    </b-button>
                  </div>
                </template>
              </b-table>

            </div>
          </b-overlay>

          <b-modal
            id="transfer-info"
            :hide-header="true"
            :hide-footer="true"
            :centered="true"
            size="lg"
          >
            <!-- $bvModal.hide(nama modal id) untuk tutup modal-->
            <emp-info
              :subsidiary="tempSubID"
              :transfer-type="type"
              @close="$bvModal.hide('transfer-info'), updateHistory()"
              @transfer-employee="$bvModal.hide('transfer-info')"
            />
          </b-modal>
          <b-modal
            id="csv-template"
            :hide-header="true"
            :hide-footer="true"
            :centered="true"
            size="lg"
          >
            <div
              class="d-flex flex-column justify-content-center"
              style="height: 250px"
            >
              <p class="saj-title d-flex justify-content-center pb-2">
                {{ $t("Please choose your template.") }}
              </p>
              <div class="d-flex justify-content-center p-1">
                <b-button
                  variant="primary"
                  class="mr-1 d-flex align-items-center justify-content-center saj-button"
                  style=""
                  :href="`${publicPath}assets/sample_excel_for_transfer.xlsx`"
                  download
                  @click="$bvModal.hide('csv-template')"
                >
                  {{ $t("Transfer Template CSV") }}
                </b-button>
                <b-button
                  variant="primary"
                  class="mr-1 d-flex align-items-center justify-content-center saj-button"
                  style=""
                  :href="`${publicPath}assets/sample_excel_for_promotion.xlsx`"
                  download
                  @click="$bvModal.hide('csv-template')"
                >
                  {{ $t("Promotion Template CSV") }}
                </b-button>
                <b-button
                  variant="primary"
                  class="mr-1 d-flex align-items-center justify-content-center saj-button"
                  style=""
                  :href="`${publicPath}assets/sample_excel_for_promotion_and_transfer.xlsx`"
                  download
                  @click="$bvModal.hide('csv-template')"
                >
                  {{ $t("Promotion & Transfer Template CSV") }}
                </b-button>
              </div>
            </div>

          </b-modal>
          <b-modal
            id="csv-upload"
            :hide-header="true"
            :hide-footer="true"
            :centered="true"
            size="lg"
            :no-close-on-backdrop="true"
          >
            <div class="p-1">
              <validation-observer ref="validateTransfer">
                <p class="saj-title">
                  {{ $t("Upload CSV file and choose Type of Transfer") }}
                </p>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Upload CSV')"
                  rules="required"
                >
                  <b-form-file
                    v-model="excelFile"
                    type="file"
                    class="saj-text p-1"
                    :browse-text="$t('Choose File')"
                    :placeholder="$t('No file choosen')"
                    style="width:100%; text-align: start; "
                    accept=".xlsx"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <p class="mt-2 saj-text">
                  {{ $t("Transfer Type") }}
                </p>

                <validation-provider
                  #default="{ errors }"
                  :name="$t('Transfer Type')"
                  rules="required"
                >
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="selectedOpt"
                    :options="transferOpt"
                    stacked
                    class="saj-text"
                    size="sm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </validation-observer>
              <div class="d-flex justify-content-center mt-1">
                <button
                  class="btn m-1 saj-button"
                  style="
                          color: white;
                          background: #0b103c !important;
                          border-color: #0b103c !important;
                        "
                  @click="importExcel()"
                >
                  {{ $t('Save') }}
                </button>
                <button
                  class="btn btn-close m-1 saj-button"
                  style="
                  color: white;
                  background: #ff0000;
                "
                  @click="$bvModal.hide('csv-upload'), selectedOpt = null"
                >
                  {{ $t('Cancel') }}
                </button>
              </div>
            </div>
          </b-modal>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            v-b-modal.csv-template
            class="mr-1 Margin saj-button"
            style=""
            variant="primary"
          >
            {{ $t("Template") }}<span style="margin-left: 3px;">CSV</span>
          </b-button>
          <b-button
            v-b-modal.csv-upload
            class="mr-1 Margin saj-button"
            style=""
            variant="primary"
          >
            {{ $t("Import CSV") }}
          </b-button>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {
  // BFormRadio,
  BModal,
  BFormInput,
  // BRow,
  BTable,
  //   BCol,
  BButton,
  BOverlay,
  // BButtonGroup,
  BFormFile,
  BFormRadioGroup,
} from "bootstrap-vue"
// import SAJToast from '@/component/saj-toastification.vue'
import empInfo from '@/component/transfer-promotion/transfer-info.vue'
import SAJToast from "@/component/saj-toastification.vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapGetters } from 'vuex'

export default {
  components: {
    empInfo,
    BModal,
    // BFormRadio,
    BFormInput,
    // BRow,
    BTable,
    // BCol,
    BButton,
    BOverlay,
    // BButtonGroup,
    BFormFile,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
  },

  data(){
    return {
      publicPath: process.env.BASE_URL,
      type: null,
      tempSubID: null,
      companyList: [],
      subsidiaryList: [],
      subsidiary_id: null,
      searchCompany: null,
      tabIndex: 1,
      show: true,
      currentPage: 1,
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'subsidiary_name', label: 'Company Name', tdClass: 'text-center',
        },
        {
          key: 'action', label: 'Action', tdClass: 'text-center',
        },
      ],
      selectedOpt: null,
      transferOpt: [
        { text: `${this.$i18n.t('Transfer')}`, value: 'transfer' },
        { text: `${this.$i18n.t('Promotion')}`, value: 'promotion' },
        { text: `${this.$i18n.t('Promotion & Transfer')}`, value: 'transfer_and_promotion' },
      ],
      excelFile: null,
      required,
    }
  },

  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  watch: {
    $route: {
      handler(){
        this.getSub_Id()
      },
    },

    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.transferOpt = [
        { text: `${this.$i18n.t('Transfer')}`, value: 'transfer' },
        { text: `${this.$i18n.t('Promotion')}`, value: 'promotion' },
        { text: `${this.$i18n.t('Promotion & Transfer')}`, value: 'transfer_and_promotion' },
      ]
    },
  },

  mounted(){
    this.getSub_Id()
  },

  methods: {
    importExcel(){
      this.$refs.validateTransfer.validate().then(success => {
        if (success){
          const data = new FormData()

          data.append('file', this.excelFile)
          data.append('type', this.selectedOpt)

          this.$axios.post(`${this.$baseUrl}/transfer_promotion/import_promotion_employee`, data).then(() => {
            this.selectedOpt = null
            this.$bvModal.hide('csv-upload')
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Your Excel file is currently being processed')}!`,
                  icon: "CheckCircleIcon",
                  variant: "info",
                  titleColor: "#000",
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
            // console.log('res', res)
          }).catch(() => {
          })
        }
      })
      // console.log('import')
    },
    getSub_Id() {
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
        this.subsidiary_id = response.data.data.employee.business_unit
        this.checkUser()
        // console.log("siniii sub id user", this.subsidiary_id)
      })
    },
    checkUser() {
      const currentRole = this.roles.selectedRole
      if (currentRole === 6){
        this.getListHR()
      } else if (currentRole === 1){
        this.getListSuperHR()
      }
      // console.log("sini current role", currentRole)
    },
    getListSuperHR() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`, data).then(response => {
        if (response.data.data.length !== 0){
          this.show = false
          this.companyList = response.data.data.subsidiaries
          // console.log(this.companyList)
          // console.log("heree", response.data.data.subsidiaries)
          this.companyList.forEach(list => {
            this.subsidiaryList.push(list.id)
          })
          // console.log("subsidiary id list", this.subsidiaryList)
        }

        this.rows = this.companyList.length
      }).catch(() => {
        this.show = false
      })
    },
    getListHR() {
      // console.log()
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll?id=${this.subsidiary_id}`).then(response => {
        if (response.data.data.length !== 0){
          this.show = false
          this.companyList = response.data.data.subsidiaries
          this.companyList.forEach(list => {
            this.subsidiaryList.push(list.id)
            // console.log("subsidiary id ", list.id)
          })
          // console.log(response)
          // console.log("subsidiary id list", this.subsidiaryList)
        }
      }).catch(() => {
        this.show = false
      })
    },
    updateHistory(){
      this.$emit('update-history')
    },
  },
}
</script>
<style scoped>
.Margin {
  margin : 0.5rem;
}
</style>
