<template>
  <div>
    <div
      class="card"
      style="background: #ffffff;
                box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                border-radius: 10px;
                overflow-y:auto;
                min-height:370px;"
    >
      <div
        class="pl-3 pr-3 pt-2"
      >
        <div class="">
          <span class="saj-title">
            {{ $t("Search Filter") }}
          </span>
        </div>
        <div class="row align-items-end mb-1">
          <!-- if admin -->
          <!-- <template v-if="roles.selectedRole === 1"> -->
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Subsidiary") }}:</label>
            <v-select
              v-model="selectedSub"
              class="saj-text select-size-sm"
              style="background: white"
              :placeholder="$t('Choose Subsidiary')"
              :options="subList"
              :reduce="sub => sub.value"
              label="text"
              @input="getDepartment(selectedSub), selectedSub === null ? selectedDepartment = null : ''"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 pt-1"
          >
            <label class="saj-text">{{ $t("Department") }}:</label>
            <v-select
              v-model="selectedDepartment"
              class="saj-text select-size-sm"
              style="background: white"
              :placeholder="$t('Choose Department')"
              :options="departmentList"
              :reduce="dept => dept.value"
              label="text"
              :disabled="selectedSub === null"
            />
          </b-col>
          <!-- </template> -->
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Employee Number") }}:</label>
            <b-form-input
              v-model="selectedEmployeeNum"
              :placeholder="$t('Search Employee Number')"
              size="sm"
              class="saj-text"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Employee Name") }}:</label>
            <b-form-input
              v-model="selectedEmployeeName"
              :placeholder="$t('Search Employee Name')"
              size="sm"
              class="saj-text"
            />
          </b-col>
          <div class="d-flex mt-1">
            <b-col>
              <b-button
                md="4"
                variant="primary"
                class="d-flex justify-content-center"
                style=""
                @click="show=true, getInactiveEmployee()"
              >

                <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
              </b-button>
            </b-col>
            <b-col>
              <b-button
                md="4"
                variant="primary"
                class="saj-title d-flex justify-content-center"
                block
                style="
            "
                @click="clearButton()"
              >
                <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
              </b-button>
            </b-col>
          </div>
          <!-- <div class="p-1 bg-success" style="width: 500px; overflow: auto;">
            {{ finalData }}
          </div> -->

        </div>
        <div
          class=""
        >
          <b-overlay
            :show="show"
            rounded="sm"
          >
            <template #overlay>
              <div class="d-flex flex-column align-items-center justify-content-center">
                <b-spinner
                  variant="primary"
                  label="Spinning"
                />
                <p>{{ $t('Fetching Data') }}... </p>
              </div>
            </template>
            <div class="row pl-1 pr-1">
              <b-table
                class="styleTable mt-1"
                style=""
                :items="inactiveEmpList"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                label-size="lg"
                bordered
                show-empty
                responsive
              >
                <template #head()="data">
                  <span
                    class="saj-text d-flex justify-content-center"
                  >{{ $t(data.label) }}</span>
                </template>
                <template #cell(index)="data">
                  <div
                    class="saj-text d-flex justify-content-center"
                  >
                    {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage-1) *10) }}
                  </div>
                </template>
                <template #empty="">
                  <h4 style="text-align: center; font-style: italic">
                    {{ $t('There are no records to show') }}
                  </h4>
                </template>
                <template #cell(employee_number)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.employee_number }}
                  </div>
                </template>
                <template #cell(employee_name)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.full_name }}
                  </div>
                </template>
                <template #cell(subsidiary_name)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.subsidiary_name }}
                  </div>
                </template>
                <template #cell(department_name)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.department_name }}
                  </div>
                </template>
                <template #cell(action)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    <div>
                      <b-col>
                        <b-button
                          v-b-modal.activate-employee
                          v-b-tooltip
                          :title="$t('Activate Employee')"
                          variant="outline-success"
                          @click="empNum = data.item.employee_number, userID = data.item.user_id, employeeData = data.item"
                        >
                          {{ $t("Activate") }}
                        </b-button>
                      </b-col>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <b-row v-if="inactiveEmpList.length > 0">
              <b-col>
                <span
                  class="saj-text"
                  align="start"
                >
                  {{ $t("Showing") }} {{ rows > perPage*currentPage ? perPage*currentPage : rows }} {{ $t("from") }} {{ rows }} {{ $t("entries") }}
                  <!-- {{ $t("Showing") }} 1 {{ $t("to") }} 2 {{ $t("of") }} 3 {{ $t("entries") }} -->
                </span>
              </b-col>
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  pills
                  aria-controls="employee-table"
                  class="saj-text mb-1 justify-content-end"
                />
              </b-col>
            </b-row>
          </b-overlay>
          <div
            v-if="inactiveEmpList.length > 0"
            class="d-flex justify-content-center mb-2"
          >
            <b-button
              v-b-modal.activate-all-employee
              variant="primary"
              md="4"
              class="p-1 saj-button btn-info mb-1"
              style=""
            >

              {{ $t('Activate All Employee') }}
            </b-button>
          </div>

          <b-modal
            id="activate-employee"
            :hide-header="true"
            :hide-footer="true"
            :centered="true"
            size="lg"
          >
            <!-- $bvModal.hide(nama modal id) untuk tutup modal-->
            <activateEmp
              :user-id="userID"
              :employee-info="employeeData"
              @refresh-table="getInactiveEmployee()"
              @close="$bvModal.hide('activate-employee')"
              @activate-employee="$bvModal.hide('activate-employee')"
            />
          </b-modal>
          <b-modal
            id="activate-all-employee"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
          >
            <activeAllEmployee
              :title="$t('Are you sure to activate all employee?')"
              :confirm-btn="$t('Yes')"
              @cancel="$bvModal.hide('activate-all-employee')"
              @confirm="activateAllEmp(), $bvModal.hide('activate-all-employee')"
            />
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  // BFormRadio,
  // BModal,
  BFormInput,
  // BFormSelect,
  BRow,
  BTable,
  BCol,
  BButton,
  VBTooltip,
  BOverlay,
  // BButtonGroup,
  BPagination,
  BSpinner,

} from "bootstrap-vue"
import activateEmp from "@/component/transfer-promotion/former-employee-activate.vue"
import activeAllEmployee from '@/pages/alert-modal.vue'
import vSelect from 'vue-select'
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    activateEmp,
    BFormInput,
    BRow,
    BTable,
    BCol,
    BButton,
    BOverlay,
    BPagination,
    BSpinner,
    vSelect,
    activeAllEmployee,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data(){
    return {
      subList: [],
      selectedSub: null,
      departmentList: [],
      selectedDepartment: null,
      selectedEmployeeNum: null,
      selectedEmployeeName: null,
      subsidiary_id: null,
      show: true,
      currentPage: 1,
      perPage: 10,
      rows: null,
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'employee_number', label: 'Employee No.', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'employee_name', label: 'Name', thClass: 'text-center',
        },
        {
          key: 'subsidiary_name', label: 'Subsidiary', thClass: 'text-center',
        },
        {
          key: 'department_name', label: 'Department', thClass: 'text-center',
        },
        {
          key: 'action', label: 'Action', tdClass: 'text-center',
        },

      ],
      inactiveEmpList: [],
      allbusinessUnit: null,
      alldepartment: null,
      empNum: null,
      userID: null,
      employeeData: null,
    }
  },

  computed: {
    ...mapGetters(['roles']),
  },

  mounted(){
    this.getSubsidiary()
    this.getInactiveEmployee()
  },

  methods: {
    clearButton(){
      this.selectedDepartment = null
      this.selectedEmployeeNum = null
      this.selectedEmployeeName = null
      this.selectedSub = null
      this.show = true
      this.currentPage = 1
      this.getInactiveEmployee()
    },
    getInactiveEmployee(){
      const params = new URLSearchParams()

      if (this.selectedSub !== null){
        params.append('subsidiary_id', this.selectedSub)
      }

      if (this.selectedDepartment !== null){
        params.append('department_id', this.selectedDepartment)
      }

      if (this.selectedEmployeeNum !== null){
        params.append('employee_number', this.selectedEmployeeNum)
      }

      if (this.selectedEmployeeName !== null){
        params.append('employee_name', this.selectedEmployeeName)
      }

      this.$axios.get(`${this.$baseUrl}/employees/getInActiveEmployees`, { params }).then(response => {
        if (response.data.data.length !== 0){
          this.inactiveEmpList = response.data.data
        } else {
          this.show = false
          this.inactiveEmpList = []
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('No record retrieved')}.`,
                icon: "InfoIcon",
                variant: "success",
                titleColor: "#000",
                iconBg: '#53b7db',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
        this.show = false
        this.rows = this.inactiveEmpList.length
      })
    },
    getSubsidiary(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        this.allbusinessUnit = response.data.data.subsidiaries
        this.subList = this.allbusinessUnit.map(b => ({
          text: b.subsidiary_name,
          value: b.id,
        }))
      })
    },
    getDepartment(dept){
      if (dept !== null){
        this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${dept}`).then(response => {
          const result = response.data
          if (result.success){
            this.alldepartment = response.data.data

            this.departmentList = this.alldepartment.map(d => ({
              text: d.name,
              value: d.id,
            }))

            this.selectedDepartment = null
            // }
          } else {
            this.departmentList = []
            this.selectedDepartment = null
          }
        })
      }
    },
    activateAllEmp(){
      const data = new FormData()
      if (this.selectedSub !== null) {
        data.append('subsidiary_id', this.selectedSub)
      }
      if (this.selectedDepartment !== null) {
        data.append('department_id', this.selectedDepartment)
      }

      // data.forEach(x => {
      //   console.log(x)
      // })

      this.$axios.post(`${this.$baseUrl}/employees/setActiveAllEmployee`, data)
        .then(() => {
          this.$emit('close')
          this.getInactiveEmployee()

          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Successfully activate all employee')}.`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
          }, 500)
        })
    },
  },
}
</script>
  <style scoped>
  .Margin {
    margin : 0.5rem;
  }
  ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
  }

  :hover::-webkit-scrollbar {
      width: 10px;
      height: 10px;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
  }

  :hover::-webkit-scrollbar-thumb {

      /* background-color: #a0a0a5 !important; */
      background-color: rgb(255, 0, 0)
  }

  ::-webkit-scrollbar-thumb:hover {
      border: 2px solid #bbb9b9 !important;
  }
  </style>
