<template>
  <div class="">
    <div class="row">
      <div class="col-lg">
        <div
          class="card"
          style="
              border-radius: 10px;
            "
        >
          <b-tabs
            v-model="tabIndex"
            class="saj-title p-1"
          >
            <b-tab
              :title="$t('History Transfer')"
              active
            >
              <historyTrans
                v-if="userDetails.business_unit !== null"
                :key="refreshHistory"
                :subsidiary="userDetails.business_unit"
              />
            </b-tab>
            <b-tab :title="$t('Company List')">
              <companyList
                v-if="userDetails.business_unit !== null"
                :subsidiary="userDetails.business_unit"
                @update-history="forceRerender"
              />
            </b-tab>
            <b-tab :title="$t('Former Employee')">
              <formerEmployee
                :subsidiary="userDetails.business_unit"
                @update-history="forceRerender"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  // BFormRadio,
  // BRow,
  // BCol,
  // BButton,
  // BButtonGroup,
  BTabs,
  BTab,
} from "bootstrap-vue"
import historyTrans from '@/component/transfer-promotion/history-transfer.vue'
import companyList from '@/component/transfer-promotion/company-list.vue'
import formerEmployee from '@/component/transfer-promotion/former-employee.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    // BFormRadio,
    // BRow,
    // BCol,
    // BButton,
    // BButtonGroup,
    BTabs,
    BTab,
    historyTrans,
    companyList,
    formerEmployee,
    // grade,
  },

  data(){
    return {
      tabIndex: 1,
      subsidiary_id: null,
      refreshHistory: 0,
    }
  },

  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },

  mounted(){
    // this.getSub_Id()
    // console.log(this.userDetails)
  },

  methods: {
    // getSub_Id() {
    //   this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
    //     this.subsidiary_id = response.data.data.employee.business_unit
    //   })
    // },
    forceRerender(){
      this.refreshHistory += 1
    },
  },
}
</script>
<style scoped>
.Margin {
  margin : 0.5rem;
}
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
}

:hover::-webkit-scrollbar-thumb {

    /* background-color: #a0a0a5 !important; */
    background-color: rgb(255, 0, 0)
}

::-webkit-scrollbar-thumb:hover {
    border: 2px solid #bbb9b9 !important;
}
</style>
