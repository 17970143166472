<template>

  <div
    class="p-1"
    style="width: auto; height: 80vh; overflow-y: scroll;"
  >
    <validation-observer ref="tansferEmp">

      <div
        v-if="transferType === 'Transfer'"
        class="mb-3 d-flex justify-content-center"
        style="font-size: 30px;
                font-weight: 600;"
      >
        <!-- section 1 -->
        {{ $t ("Transfer Info") }}
      </div>
      <div
        v-else-if="transferType === 'Promotion'"
        class="mb-3 d-flex justify-content-center"
        style="font-size: 30px;
                font-weight: 600;"
      >
        <!-- section 1 -->
        {{ $t ("Promotion Info") }}
      </div>
      <div
        v-else
        class="mb-3 d-flex justify-content-center"
        style="font-size: 30px;
                font-weight: 600;"
      >
        <!-- section 1 -->
        {{ $t ("Promotion & Transfer Info") }}
      </div>
      <div
        class="mb-1 saj-title d-flex justify-content-start"
        style=""
      >
        {{ $t("Section ") }}1 : {{ $t("Employee Details") }}
      </div>

      <div
        class="row mb-1"
      >
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Employee Number") }} :
          </div>
          <!-- {{ empData }} -->
          <v-select
            v-model="empData.employee_number"
            :placeholder="$t('Employee Number')"
            class="saj-form-text select-size-lg"
            rows="8"
            :options="nameList"
            :value="nameList"
            label="empNo"
            :reduce="emp => emp.value"
            @input="selectedEmployee(empData.employee_number), status = 'no'"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Employee Name") }} :
          </div>

          <b-form-input
            v-model="empData.employeeName"
            size="lg"
            disabled
            class="saj-text m-0"
            :placeholder="$t('Employee Name')"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Identification Number") }} :
          </div>
          <b-form-input
            v-model="empData.ic"
            :placeholder="$t('Eg: xxxxxxxxxxxx')"
            type="number"
            size="lg"
            disabled
            class="saj-form-text"
            rows="8"
          />
        </div>

        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Email") }} :
          </div>
          <b-form-input
            v-model="empData.email"
            :placeholder="$t('Email')"
            size="lg"
            disabled
            class="saj-form-text"
            rows="8"
          />
        </div>

        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Subsidiary") }} :
          </div>
          <b-form-input
            v-model="empData.business_unit"
            :placeholder="$t('Subsidiary')"
            size="lg"
            disabled
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Department") }} :
          </div>
          <b-form-input
            v-model="empData.department"
            :placeholder="$t('Department')"
            size="lg"
            disabled
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Work Location") }} :
          </div>
          <b-form-input
            v-model="empData.work_location"
            :placeholder="$t('Work Location')"
            size="lg"
            disabled
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-6 saj-text">

          <div class="m-0 mt-1 mb-1">
            {{ $t ("Position") }} :
          </div>
          <b-form-input
            v-model="empData.position"
            :placeholder="$t('Position')"
            size="lg"
            disabled
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-6 saj-text">

          <div class="m-0 mt-1 mb-1">
            {{ $t ("Grade") }} :
          </div>
          <b-form-input
            v-model="empData.grade"
            :placeholder="$t('Grade')"
            size="lg"
            disabled
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Date Hired") }} :
          </div>
          <input
            v-model="empData.hire_date"
            type="date"
            disabled
            style="height: 3.25rem;"
            class="form-control"
          >
        </div>
        <div
          v-if="transferType !== 'Promotion'"
          class="col-lg-6 saj-text"
        >
          <div
            class="m-0 mt-1 mb-1"
          >
            {{ $t ("Reason for transfer") }} :
          </div>
          <b-form-textarea
            v-model="empData.reason"
            size="lg"
            rows="6"
            class="saj-text m-0"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Achievement") }} :
          </div>
          <b-form-textarea
            v-model="empData.achievement"
            size="lg"
            rows="6"
            class="saj-text m-0"
          />
        </div>
      </div>

      <!-- section 2 -->
      <div
        class="row pt-1 pb-2"
        style=""
      >
        <div class="col">
          <div
            class="row pl-1"
          >
            <div
              class="saj-title mb-1"
            >
              {{ $t("Section ") }}2 : {{ $t("Supervisor Details") }}
            </div>
          </div>
          <div
            class="row"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Supervisor Id") }} :
              </div>
              <b-form-input
                v-model="empData.sv_employee_number"
                size="lg"
                disabled
                class="saj-text m-0"
                :placeholder="$t('Supervisor Id')"
              />
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Supervisor Name") }} :
              </div>

              <b-form-input
                v-model="empData.supervisorName"
                size="lg"
                disabled
                class="saj-text m-0"
                :placeholder="$t('Supervisor Name')"
              />
            </div>

            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Email") }} :
              </div>
              <b-form-input
                v-model="empData.sv_email"
                :placeholder="$t('Email')"
                size="lg"
                disabled
                class="saj-form-text"
                rows="8"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- section 3 -->
      <div
        class="row pt-1"
        style=""
      >
        <div class="col">
          <div
            class="row pl-1"
          >
            <div
              v-if="transferType === 'Transfer'"
              class="saj-title mb-1"
            >
              {{ $t("Section ") }}3 : {{ $t("Transfer Information") }}
            </div>
            <div
              v-else-if="transferType === 'Promotion'"
              class="saj-title mb-1"
            >
              {{ $t("Section ") }}3 : {{ $t("Promotion Information") }}
            </div>
            <div
              v-else
              class="saj-title mb-1"
            >
              {{ $t("Section ") }}3 : {{ $t("Promotion & Transfer Information") }}
            </div>
          </div>
          <div
            class="row"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Subsidiary") }}:
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Subsidiary')"
                rules="required"
              >
                <v-select
                  v-model="empData.new_business_unit"
                  :placeholder="$t('Select Subsidiary')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="businessUnitList"
                  :reduce="text => text.value"
                  label="text"
                  :disabled="transferType === 'Promotion'"
                  :clearable="transferType !== 'Promotion'"
                  @input="empData.new_business_unit !== null ? (getDepartment(empData.new_business_unit), getWorkLocation(empData.new_business_unit)) : status = 'no'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div
                v-if="transferType !== 'Promotion'"
                class="mb-1"
                style="margin-top: 5px"
              >
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="status"
                  name="checkbox-1"
                  value="yes"
                  unchecked-value="no"
                  size=""
                  :disabled="empData.employee_number === null"
                  @input="status === 'yes' ? (empData.new_business_unit = infoEmp.employee.business_unit, getDepartment(empData.new_business_unit), getWorkLocation(empData.new_business_unit)) : empData.new_business_unit = null"
                >
                  <span style="font-style: italic;">{{ $t('Within current subsidiary') }}</span>
                </b-form-checkbox>
              </div>

            </div>

            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Department") }}:
              </div>
              <v-select
                v-model="empData.new_department_id"
                :placeholder="$t('Select Department')"
                class="saj-form-text select-size-lg"
                rows="8"
                :disabled="empData.new_business_unit === null"
                :options="departmentList"
                :reduce="text => text.value"
                label="text"
              />
            </div>

          </div>
          <div
            class="row"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Work Location") }} :
              </div>
              <v-select
                v-model="empData.new_branch_id"
                :placeholder="$t('Select Work Location')"
                class="saj-form-text select-size-lg"
                rows="8"
                :disabled="empData.new_business_unit === null"
                :options="branchList"
                :reduce="text => text.value"
                label="text"
              />
            </div>

            <div
              v-if="transferType !== 'Transfer'"
              class="col-lg-6 saj-text"
            >
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Position") }} :
              </div>
              <v-select
                v-model="empData.new_position"
                :placeholder="$t('Select Position')"
                class="saj-form-text select-size-lg"
                rows="8"
                :options="positionList"
                :value="positionList"
                :reduce="val => val.value"
              />
              <div
                class="mb-1"
                style="margin-top: 5px"
              >
                <b-form-checkbox
                  id="checkbox-2"
                  v-model="carry_position"
                  name="checkbox-2"
                  value="yes"
                  unchecked-value="no"
                  size=""
                  :disabled="empData.employee_number === null"
                  @input="carry_position === 'yes' ? empData.new_position = infoEmp.employee.position_id : empData.new_position = null"
                >
                  <span style="font-style: italic;">{{ $t('Keep current position') }}</span>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div
            class="row"
          >
            <div
              v-if="transferType !== 'Transfer'"
              class="col-lg-6 saj-text"
            >
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Grade") }} :
              </div>
              <v-select
                v-model="empData.new_grade"
                :placeholder="$t('Select Grade')"
                class="saj-form-text select-size-lg"
                rows="8"
                :options="gradeList"
                :value="gradeList"
                :reduce="val => val.value"
              />
              <div
                class="mb-1"
                style="margin-top: 5px"
              >
                <b-form-checkbox
                  id="checkbox-3"
                  v-model="carry_grade"
                  name="checkbox-3"
                  value="yes"
                  unchecked-value="no"
                  size=""
                  :disabled="empData.employee_number === null"
                  @input="carry_grade === 'yes' ? empData.new_grade = empData.grade : empData.new_grade = null"
                >
                  <span style="font-style: italic;">{{ $t('Keep current grade') }}</span>
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-lg-6 saj-text">
              <div
                v-if="transferType === 'Transfer'"
                class="m-0 mt-1 mb-1"
              >
                {{ $t ("Date of Transfer") }} :
              </div>
              <div
                v-else-if="transferType === 'Promotion'"
                class="m-0 mt-1 mb-1"
              >
                {{ $t ("Date of Promotion") }} :
              </div>
              <div
                v-else
                class="m-0 mt-1 mb-1"
              >
                {{ $t ("Date of Promotion & Transfer") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Date')"
                rules="required"
              >
                <input
                  v-model="empData.join_date"
                  type="date"
                  style="background: #fff; height: 3.25rem;"
                  class="form-control"
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-end"
        style=""
      >
        <button
          class="btn btn-close m-1 saj-button"
          aria-label="Close modal"
          style="

                  color: white;
                  background: #ff0000;
                "
          @click="close()"
        >
          {{ $t('Back') }}
        </button>
        <button
          class="btn m-1 saj-button"
          aria-label="submit modal"
          style="

                  color: white;
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
          @click="transferType === 'Transfer' ? transferEmployee() : promoteEmployee()"
        >
          {{ $t('Save') }}
        </button>
      </div>

    </validation-observer>
  </div>

</template>
<script>
import {
//   BForm,
  BFormInput,
  // BFormGroup,
  BFormTextarea,
  // BFormSelect,
  // BFormFile,
  BFormCheckbox,
} from "bootstrap-vue"
// import flatPickr from "vue-flatpickr-component"
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from 'vee-validate/dist/rules'
import SAJToast from '@/component/saj-toastification.vue'
// import moment from "moment"

export default {
  components: {
    // BButton,
    // BModal,
    // BForm,
    BFormInput,
    // BFormGroup,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  props: {
    subsidiary: {
      type: Number,
      default: null,
    },
    transferType: {
      type: String,
      default: null,
    },
    // empInfo: {
    //   type: Object,
    //   default: () => {},
    // },

  },
  data() {
    return {
      empData: {
        employee_number: null,
        employeeName: null,
        ic: null,
        email: null,
        business_unit: null,
        department: null,
        work_location: null,
        position: null,
        grade: null,
        hire_date: null,
        reason: null,
        achievement: null,
        sv_employee_number: null,
        supervisorName: null,
        sv_email: null,
        new_business_unit: this.transferType === 'Promotion' ? this.subsidiary : null,
        new_department_id: null,
        new_branch_id: null,
        new_position: null,
        new_grade: null,
        join_date: null,

      },
      required,
      subsidiaryID: null,
      businessUnitList: [],
      departmentList: [],
      branchList: [],
      positionList: [],
      nameList: [],
      infoEmp: null,
      status: 'no',
      carry_position: 'no',
      carry_grade: 'no',
      gradeList: [],
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  mounted() {
    this.getSubsidiary()
    // this.getDepartment()
    // this.getWorkLocation()
    this.getPosition()
    this.getMemberName()
    if (this.transferType === 'Promotion'){
      this.getDepartment(this.subsidiary)
      this.getWorkLocation(this.subsidiary)
    }
    this.getGrade()
    // console.log('emp data', this.employeeData)
    // console.log('type', this.transferType)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getGrade(){
      this.$axios.get(`${this.$baseUrl}/grades/`).then(response => {
        this.allGrade = response.data.data
        // this.allGrade.forEach(d => {
        //   this.gradeList.push(d.grade)
        // })

        this.gradeList = this.allGrade.map(d => ({
          label: d.grade,
          value: d.id,
        }
        ))
      })
    },
    getSubsidiary(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        this.allbusinessUnit = response.data.data.subsidiaries
        this.allbusinessUnit.forEach(b => {
          this.businessUnitList.push({
            text: b.subsidiary_name,
            value: b.id,

          })
        })
      })
    },
    getDepartment(dept){
      this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${dept}`).then(response => {
        const result = response.data
        if (result.success){
          this.alldepartment = response.data.data

          this.departmentList = this.alldepartment.map(d => ({
            text: d.name,
            value: d.id,
          }))
        }
      })
    },
    getWorkLocation(wl){
      this.$axios.get(`${this.$baseUrl}/branch/get_branch_by_company?company_id=${wl}`).then(response => {
        this.allBranch = response.data.data

        this.branchList = this.allBranch.map(d => ({
          text: d.name,
          value: d.id,
        }))
      })
    },
    getPosition(){
      this.$axios.get(`${this.$baseUrl}/position/get_position_all`).then(response => {
        this.allPosition = response.data.data

        this.positionList = this.allPosition.map(d => ({
          label: d.name,
          value: d.id,
        }))

        // this.allPosition.forEach(d => {
        //   this.positionList.push(d.name)
        // })
      })
    },
    getMemberName() {
      // console.log('masuk member name', this.subsidiary)
      // const params = new FormData()
      // params.append("subsidiary_id", this.id)
      this.nameList = []

      const params = new URLSearchParams()

      params.append('subsidiary_id', this.subsidiary)

      params.append('status', 'active')

      this.$axios.get(`${this.$baseUrl}/employees/getAllEmployeesNameWithoutPerformance`, { params }).then(response => {
        const allName = response.data.data

        this.nameList = allName.map(item => ({
          value: item.user_id,
          empNo: item.employee_number,
          text: item.full_name,
        }))
      })
    },
    selectedEmployee(selected){
      // console.log(selected)
      if (selected === null){
        this.empData = {
          employee_number: null,
          employeeName: null,
          ic: null,
          email: null,
          business_unit: null,
          department: null,
          work_location: null,
          position: null,
          grade: null,
          hire_date: null,
          reason: null,
          achievement: null,
          sv_employee_number: null,
          supervisorName: null,
          sv_email: null,
          new_business_unit: null,
          new_department_id: null,
          new_branch_id: null,
          new_position: null,
          new_grade: null,
          join_date: null,

        }
      } else {
        const params = new URLSearchParams()

        params.append('user_id', selected)

        this.$axios.get(`${this.$baseUrl}/supervisor/getEmployee`, { params }).then(response => {
          this.infoEmp = response.data.data
          this.empData.employeeName = this.infoEmp.name
          this.empData.ic = this.infoEmp.employee.ic
          this.empData.email = this.infoEmp.email
          this.empData.business_unit = this.infoEmp.employee.business_unit
          this.empData.department = this.infoEmp.department
          this.empData.work_location = this.infoEmp.branch
          this.empData.position = this.infoEmp.employee.position
          this.empData.grade = this.infoEmp.employee.grade
          this.empData.hire_date = this.infoEmp.employee.hire_date
          // this.empData.hire_date = moment(this.infoEmp.employee.hire_date, "").format("D-MM-yyyy")
          this.empData.sv_employee_number = this.infoEmp.sv_employee_number
          this.empData.supervisorName = this.infoEmp.supervisor
          this.empData.sv_email = this.infoEmp.supervisor_email
          // console.log('user', this.infoEmp)
        })
      }
    },
    transferEmployee(){
      this.$refs.tansferEmp.validate().then(success => {
        if (success){
          const data = new FormData()

          data.append('employee_id', this.infoEmp.employee.id)
          data.append('date_hired', this.empData.join_date)
          data.append('position_id', this.empData.new_position !== null ? this.empData.new_position : this.infoEmp.employee.position_id)

          if (this.empData.new_business_unit !== null){
            data.append('subsidiary_id', this.empData.new_business_unit)
          }

          if (this.empData.new_department_id !== null){
            data.append('department_id', this.empData.new_department_id)
          }

          if (this.empData.new_branch_id !== null){
            data.append('branch_id', this.empData.new_branch_id)
          }

          // if (this.infoEmp.employee.supervisor_id !== null){
          //   data.append('sv_id', this.infoEmp.employee.supervisor_id)
          // }

          if (this.empData.reason !== null){
            data.append('reason', this.empData.reason)
          }

          if (this.empData.achievement !== null){
            data.append('achievement', this.empData.achievement)
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const pair of data.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`)
          }

          this.$axios
            .post(`${this.$baseUrl}/transfer_promotion/transferEmployee`, data)
            .then(() => {
              // console.log('res', res)
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t(this.transferType)} ${this.$t('success')} !`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    titleColor: "#000",
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
              this.close()
            })

          // console.log('berjaya transfer')
        }
      })
    },

    promoteEmployee(){
      this.$refs.tansferEmp.validate().then(success => {
        if (success){
          const data = new FormData()

          data.append('employee_id', this.infoEmp.employee.id)
          data.append('grade_id', this.empData.new_grade !== null ? this.empData.new_grade : this.infoEmp.employee.grade_id)
          data.append('type', this.transferType)
          data.append('position_id', this.empData.new_position !== null ? this.empData.new_position : this.empData.position)
          data.append('date_hired', this.empData.join_date)

          if (this.empData.new_business_unit !== null){
            data.append('subsidiary_id', this.empData.new_business_unit)
          }
          if (this.empData.new_department_id !== null){
            data.append('department_id', this.empData.new_department_id)
          }
          if (this.empData.reason !== null){
            data.append('reason', this.empData.reason)
          }
          if (this.empData.new_branch_id !== null){
            data.append('branch_id', this.empData.new_branch_id)
          }
          if (this.empData.achievement !== null){
            data.append('achievement', this.empData.achievement)
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const pair of data.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`)
          }

          this.$axios
            .post(`${this.$baseUrl}/transfer_promotion/promotionEmployee`, data)
            .then(res => {
              console.log('ini res promo trans', res)
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t('Employee successfully transferred')}.`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    titleColor: "#000",
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
              this.close()
            })

          // data.append('sv_id', )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
  .dateJoined .flatpickr-input, .flatpickr-input ~ .form-control, .flatpickr-human-friendly {
  height: 53px !important
}
</style>

<!-- .flatpickr-input, .flatpickr-input ~ .form-control, .flatpickr-human-friendly -->
